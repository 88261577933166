/**
 * 数值预报图层
 * 包含等值线、等值面、标注
 */
export var MyLayers = {
    options: {
        stroke: true,
        strokeColor: '#A52829',
        weight: 2,
        opacity: 1,
        fill: true,
        fillOpacity: 0.8,
        label: false,
        labelColor: '#A52829',
        labelSize: '12px',
        grid: true,
        gridValueColor: '#444',
        gridDistanceScale: 1,
        mapFirstLoadComplete: false,
    },
    map: null,
    isolineLayer: null,
    isosurfaceLayer: null,
    isolabelLayer: null,
    initialize: function (options) {
        this._layers = {};
        this.options = Object.assign({}, this.options, options);
        this.map = options.map;
        this.tdMap = options.tdMap;
        this.clear(this.map);
        // if (this.options.grid) {
        this.gridLayer = new GridValueLayer({
            map: this.map, tdMap:this.tdMap, valueColor: this.options.gridValueColor, distanceScale: this.options.gridDistanceScale, gridValueVisible: true
        });
        // console.log( this.gridLayer )
        // }
        // if (this.options.fill) {
        //     this.isosurfaceLayer = new IsosurfaceLayer({
        //         map: this.map, layerVisible: true
        //     });
        // }
    },
    setData: function(data){
      // debugger
        var jsonData = data;
        if (data != null) {
            var row = data.Data.length;
            var col = data.Data[0].length;

            jsonData.latsize = row;
            jsonData.lonsize = col;

            jsonData.nlon = (data.MaxLon - data.MinLon) / col;//每个格子所占坐标宽度
            jsonData.nlat = (data.MaxLat - data.MinLat) / row;//每个格子所占坐标高度

            jsonData.startlon = data.MinLon; jsonData.startlat = data.MinLat;
            jsonData.endlon = data.MaxLon; jsonData.endlat = data.MaxLat;

        }
          // console.log(this.gridLayer)
        

        this.gridLayer.setData(jsonData);
    },
    

    clear(map) {
        // if (this.isosurfaceLayer) map.removeLayer(this.isosurfaceLayer);
        // this.CanvasLayer = null
        console.log(this.CanvasLayer);
        // if (this.gridLayer) map.removeLayer(this.gridLayer);
    },
    getcolor(sg, val) {
        var cc = new Object();
        cc.R = cc.G = cc.B = 255;
        if (sg != null) {
            var len = sg.CValues.length;
            var min = 999999;
            for (var j = 0; j < len; j++) {
                try {
                    if (min > val && val <= sg.CValues[j]) {
                        if (j > 0) j = j - 1;
                        cc = sg.Colors[j];
                        break;
                    }
                } catch (es) { 
                  // console.log(es)
                }
            }
            if (val >= sg.CValues[len - 1]) {
                cc = sg.Colors[len - 1];
            }
        }
        return cc;
    },
    distanceTo(point1, point2) {
      var x = point2['x'] - point1['x'],
      y = point2['y'] - point1['y'];

        return Math.sqrt(x * x + y * y);
    },
    deepClone(target) {
        let result;
        if (typeof (target) != 'object') return target;
        else {
            if (target == null) return target;
            // 如果是一个数组的话
            else if (Array.isArray(target)) {
                result = []; // 将result赋值为一个数组，并且执行遍历
                for (let i in target) {
                    result.push(this.deepClone(target[i]))// 递归克隆数组中的每一项
                }
            } else {
                result = new Object();
                for (var i in target)
                    result[i] = this.deepClone(target[i]);
            }
            return result;
        }
    }
}
/**
 * 格点数值图
 * */
export class GridValueLayer {
    constructor(option = {}) {
        var defaults = {
            distanceScale: 1,
            valueColor: "#f00",
            _renderer: null,
            gridValueVisible: false,
            gridWindVisible: false
        };
        this.options = Object.assign({}, defaults, option);
        this._map = option.map; 
        this.tdMap = option.tdMap
        var canvas = document.createElement("canvas");
        var size = [this._map.getSize().width, this._map.getSize().height]
        canvas.width = size[0];
        canvas.height = size[1];
        this._renderer = canvas;
    }

    setData(data) {
        this.data = data;
        if (data != null) {
            this.grid = data.Data;
            this.reset();
        }
    }
    reset() {
        this.update();
    }
    update() {
        if (this.grid && this._map) {
            this.clear();
            this.draw();
        }
    }

    draw() {
        var _this = this;
        var x0 = this.data.startlon, y0 = this.data.endlat;
        var dx = this.data.nlon, dy = this.data.nlat;
        var lx = this.data.lonsize, ly = this.data.latsize;
        //Tip:因经度间隔、纬度间隔(即nlon、nlat)一般一样
        var p1 = this._map.lngLatToContainer([x0, y0]);
        var p2 = this._map.lngLatToContainer([x0 + dx, y0]);
        var d = MyLayers.distanceTo(p1, p2);
        d = d / this.options.distanceScale;
        var xi = d > (lx - 1) * dx ? 1 : Math.ceil((lx - 1) * dx / d);
        //var yi=d>(ly-1)*dx?1:Math.ceil((ly-1)*dx/d);//Tip:因文本为横向，所以采用xi间隔，宽松点更好看
        //d=d>(this.grid.length-1)/2?(this.grid.length-1)/2:d; 
        for (var i = 0; i < this.grid.length; i += xi) {
            var row = this.grid[i];
            for (var j = 0; j < row.length; j += xi) {
                var lngLat = [x0 + dx * j, y0 - dy * i - dy];
                var p = this._map.lngLatToContainer(lngLat);
                var value = row[j];
                if (value == 9999 || value == -9999 || value == -999) {
                  console.log('异常数据')
                } else{ 
                  
                    _this.drawText(value, p);
                }
            }
        }
        var size = [_this._map.getSize().width, _this._map.getSize().height]
        var canvas = document.createElement('canvas');
        canvas.width = size[0];
        canvas.height = size[1];
        var context = canvas.getContext('2d');
        var mapsize = [_this._map.getSize().width, _this._map.getSize().height];
        var delt = new Array(2);
        delt[0] = (size[0] - mapsize[0]) / 2;
        delt[1] = (size[1] - mapsize[1]) / 2;
        var imageData = _this._renderer.getContext("2d").getImageData(0, 0, _this._renderer.width, _this._renderer.height);
        context.putImageData(imageData, delt[0], delt[1]);
        // console.log(canvas.toDataURL('image/png'))
        // var CanvasLayer = new AMap.CanvasLayer({
        //   canvas: canvas,
        //   bounds: new AMap.Bounds(
        //     [ _this.data.MinLon, _this.data.MinLat],
        //     [ _this.data.MaxLon, _this.data.MaxLat]
        //   ),
        //   zooms: [3, 18],
        // });
        // CanvasLayer.setMap(this._map);
        // this.canvasLayer=CanvasLayer;

        var imgBaes64 = canvas.toDataURL('image/png');
        this.canvasLayer = _this.tdMap.imageLayerr({ maxlat:_this.data.MaxLat, maxlng:_this.data.MaxLon, minlat:_this.data.MinLat, minlng:_this.data.MinLon }, imgBaes64);
    }

    drawText(value, p) {
        var ctx = this._renderer.getContext("2d");
        if (!ctx) return;
        if (this.data.Type == "能见度") {
            value = value / 1000;
        }
        var cc = cc = { R: 0, G: 0, B: 0 } //MyLayers.getcolor(this.data.Colors, value);
        ctx.fillStyle = 'rgb(' + cc.R + ',' + cc.G + ',' + cc.B + ')';
        ctx.textAlign = "center";
        ctx.font = "normal 14px 微软雅黑";
        // console.log(this.options.gridValueVisible)
        if (this.options.gridValueVisible){
          // console.log(parseFloat(value).toFixed(1))
            ctx.fillText(parseFloat(value).toFixed(1), p['x'], p['y'] + 5);
        }
    }
    // drawWindText(value, p) {
    //     var ctx = this._renderer.getContext("2d");
    //     if (!ctx) return;
    //     var w = WeatherDataUtil.wind_getWindByUV(value);
    //     ctx.font = "14px Arial";
    //     ctx.textAlign = "center";
    //     if (this.options.gridValueVisible) {
    //         //ctx.fillStyle = '#fff';
    //         //ctx.fillText(w.speed, p.x+1, p.y+11);
    //         ctx.fillStyle = '#000';
    //         ctx.fillText(w.speed, p.x, p.y + 10);
    //     }
    //     if (this.options.gridWindVisible)
    //         drawWindByPosition(ctx, w.speed, w.dir, p);
    // }
    // setGridVisible(value, g) {
    //     if (g == true) {
    //         this.grid = null;
    //     }
    //     this.options.gridValueVisible = value;
    //     this.update();
    // }
    clear() {
        var size = [this._map.getSize().width, this._map.getSize().height];
        // debugger
        var a = this._renderer.getContext("2d"); 
        a.clearRect(0, 0, size[0], size[1]);
        this._renderer.width = size[0];
        this._renderer.height = size[1];
        if(this.canvasLayer){this.canvasLayer.setMap(null)}
        if(this.canvasLayer)this.canvasLayer = null
    }
}
