import { color } from "echarts";


/*
 * @Author: wangchaoxu
 * @Date: 2020-07-20 18:45:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-20 17:43:15
 * @Description:坐标的转换
 */
export function Coordinate(options) {
  var defaults = {
    Width: 0,
    Height: 0,
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };

  var _Width = 0.0;
  var _Height = 0.0;

  this._minX = options.minX;
  this._maxX = options.maxX;
  this._minY = options.minY;
  this._maxY = options.maxY;
  _Width = options.Width;
  _Height = options.Height;
  this._scaleX = _Width / (this._maxX - this._minX);
  this._scaleY = _Height / (this._maxY - this._minY);
  // this.PictureBox1.Refresh();
  this._Width = _Width;
  this._Height = _Height;
}
Coordinate.prototype = {
  _Height: 0,
  _Width: 0,
  _scaleX: 0,
  _scaleY: 0,
  _minX: 0.0,
  _maxX: 0.0,
  _minY: 0.0,
  _maxY: 0.0,
  //像素转坐标
  ToCoordinate: function (sX, sY) {
    var pX = sX / this._scaleX + this._minX;
    var pY = this._maxY - sY / this._scaleY;
    var pp = new Object();
    pp.pX = pX;
    pp.pY = pY;
    return pp;
  },
  //坐标转像素
  ToScreen: function (pX, pY) {
    var sX = parseFloat((pX - this._minX) * this._scaleX);
    if (sX < 0) sX = 0;
    if (sX > this._Width) sX = this._Width;
    var sY = parseFloat((this._maxY - pY) * this._scaleY);
    if (sY < 0) sY = 0;
    if (sY > this._Height) sY = this._Height;
    var pp = new Object();
    pp.pX = parseInt(sX);
    pp.pY = parseInt(sY);
    return pp;
  },
  Contains: function (points, p) {
    //判断是否在范围内
    var result = false;
    for (var i = 0; i < points.length - 1; i++) {
      if (
        ((points[i + 1].Y <= p.Y && p.Y < points[i].Y) || (points[i].Y <= p.Y && p.Y < points[i + 1].Y)) &&
        p.X < ((points[i].X - points[i + 1].X) * (p.Y - points[i + 1].Y)) / (points[i].Y - points[i + 1].Y) + points[i + 1].X
      ) {
        result = !result;
      }
    }
    return result;
  },
};
/*
 * drawCanvas  把格点数据转为图像数据
 * @param {Array} griddata 格点数据
 * @param {Object}boundary 区域的最大最小经纬度值,以及区域的边界线数据
 * @param {Object}boundary 区域的边界线数据
 * @param {Array} CValues  和颜色对应的值
 * @param {Array} Colors   和值对应的颜色
 **/
// 格点预报
export function drawCanvas(griddata, boundary, CValues, mapData) {

  // console.log(griddata, boundary, CValues)
  // console.log(griddata)
  // var threshold = getColors({ min: CValues.section[0], max: CValues.section[CValues.section.length-1] }, CValues.section, CValues.color)
  var Icanvas = document.createElement('canvas');
  var c = Icanvas.getContext('2d');
  // griddata.reverse()
  // griddata.forEach(it=>{
  //   it.reverse()
  // })
  
  if (griddata) {
    var WI = griddata[0].length; //第一组树的长度
    var HI = griddata.length; //二维数组的长度
    // var DATADEAM2=new Array(HI);
    // for (var x = 0; x < WI; x++) {
    //   for (var y = 0; y < HI; y++) {
    //     if(DATADEAM2[y]==undefined){

    //       DATADEAM2[y]=new Array(WI);
    //     }
    //     DATADEAM2[y][x]=griddata[x][y];
    //   }
    // }
     WI = griddata.length; //第一组树的长度
     HI = griddata[0].length; //二维数组的长度
    //  min 122.41174133,46.22092187
    //  max 126.66095862,48.93056399
    // 黑龙江省 最大小经纬度
    var Maplists = {
      minlng: boundary.minlng,
      minlat: boundary.minlat,
      maxlng: boundary.maxlng,
      maxlat: boundary.maxlat,
    };
    //截取区域数据
    var DATADEAM = griddata;
    //省边界范围
    // 齐齐哈尔市 最大小经纬度
    var cd2 = new Coordinate({
      Width: WI,
      Height: HI,
      minX: 122.41174133,
      maxX: 126.66095862,
      minY: 46.22092187,
      maxY: 48.93056399,
    });
   
   
    var A = cd2.ToScreen(Maplists.minlng, Maplists.maxlat); //west north
    var C = cd2.ToScreen(Maplists.maxlng, Maplists.minlat); //east sourth
    WI = Math.abs(parseInt(C.pX - A.pX));
    HI = Math.abs(parseInt(C.pY - A.pY));
    // var OX = A.pX;
    // var OY = A.pY;
    // var OX = A.pY;
    // var OY = A.pX;
    // if (OY + HI > DATADEAM[0].length) HI = DATADEAM[0].length - OY;
    // if (OX + WI > DATADEAM.length) WI = Math.abs(DATADEAM.length - OX);
    // var DataU = new Array( WI );
    // for (var I = OY, s = 0; I < OY + HI; I++, s++) {
    //   var col = HI;
    //   DataU[s] = new Array(col);
    //   for (var J = OX, m = 0; J < OX + col; J++, m++) {
    //     if (I < 0 || I >= DATADEAM[0].length || J < 0 || J >= DATADEAM.length) {
    //       DataU[s][m] = 0;
    //     } else {
    //       DataU[s][m] = DATADEAM[J][I];
    //     }
    //   }
    // }
    griddata = resize(DATADEAM,WI, HI ); // 插值 DataU, WI,HI

    // WI = griddata[0].length; //第一组树的长度
    // HI = griddata.length; //二维数组的长度
 // debugger
    Icanvas.width = WI;
    Icanvas.height = HI;
    var data = c.createImageData(WI   ,HI);
    var len = CValues.section.length;
    
    for (var x = 0; x <WI ; x++) {
      for (var y = 0; y < HI; y++) {
    
        var cc = new Object();
        cc.R = 255;
        cc.G = 255;
        cc.B = 255;
       // var lngLat = cd2.ToCoordinate(x, y); //像素转坐标
        // var isPointInRing = AMap.GeometryUtil.isPointInRing(point,path);
        var inBoundary = true
        // var inBoundary = cd2.Contains(mapData, {
        //   //判断是否在范围内
        //   X: lngLat.pX,
        //   Y: lngLat.pY,
        // });
        if (inBoundary) {
         // let dd = griddata[x][HI -  - 1];
          let dd = griddata[x][y];
          cc=CValues.color[len - 1];
          if (dd != -99 || dd || dd == 0) {
            for (var j = 0; j < len - 1; j++) {
              try {
                if (dd <= CValues.section[0]) {
                  cc = CValues.color[0];
                  break;
                } else if (dd >= CValues.section[len - 1]) {
                  cc = CValues.color[len - 1];
                  break;
                } else if (dd >= CValues.section[j] && dd <= CValues.section[j + 1]) {
                  cc = CValues.color[j + 1];
                  break;
                }
              } catch (es) {
                console.log(es);
              }
            }
            var index = (y*WI+x)*4; //calculate index
            data.data[index] = cc[0]; // red
            data.data[index + 1] = cc[1]; // green
            data.data[index + 2] = cc[2]; // blue
            // data.data[index] = cc.R; // red
            // data.data[index + 1] = cc.G; // green
            // data.data[index + 2] = cc.B; // blue
            // 无值得时候白色
            // if (cc.R == 0 && cc.G == 0 && cc.B == 0) {
            //无值得时候透明
            
            if (cc[0] == 255 && cc[1] == 255 && cc[2] == 255) {
              data.data[index + 3] = 0;
              // console.log(data.data)
            } else {
               data.data[index + 3] = 255;
            }
          }
        }
      }
    }
    c.putImageData(data, 0, 0); //将图像数据放到画布上
    return Icanvas.toDataURL('image/png');
    // return Icanvas.toDataURL();
  } else {
    alert('暂无预报数据');
  }
}
/***
 * min 最小值 （温度）-16
 * max 最大值 （温度） 40
 */
// function getColors({min,max}, Range, colors){
//   const list = [];
//   const colorList = [];
//   for (let index = min; index <= max; index++) {
//     list.push(index);
//   }
//   for (let i = 0; i < list.length; i++) {
//     Range.forEach((it,index) => {
//         if(list[i] >= it && list[i]<Range[index+1]){
//            colorList.push(colors[index]);
//           //  console.log(it,list[i],Range[index+1],'Rangeit,list[数值],Range+1','color:'+ `rgba(${colors[index][0]},${colors[index][1]},${colors[index][2]})`)
//         }
//     });
//   }
//   console.log('list',list)
//   console.log('Range',Range)
//   console.log('colors',colors)
//   console.log('colorList',colorList)
//   return {list,colorList}
// }
/**
 * @description: 绘制色标卡
 * @param {Array} CValues 和颜色对应的值
 * @param {Array} Colors  和值对应的颜色
 * @param {Number}  clen  色标的长度
 */
export function drawCard(CValues, Colors, clen) {
  console.log(CValues, Colors)
  var isMax = CValues[0] < CValues[CValues.length - 1]; // 判断 升序降序
  var canvas_c = document.createElement('canvas');
  var ctx_c = canvas_c.getContext('2d');
  var ls = CValues.length;
  canvas_c.height = clen * ls;
  canvas_c.width = 120;
  // canvas_c.width = 70;
  for (var l in CValues) {
    let text = CValues[l];
    if (isMax /* 降序*/) {
      // console.log('%c �','color:'+'rgb(' + Colors[l][0] + ',' + Colors[l][1] + ',' + Colors[l][2] + ')', 'text', text, 'index', l)
      let num = CValues[l] == 0 ? CValues[l] : (CValues[l]).toFixed(1);
      if (l == 0) {
        text = num + ' ~ ' +  (CValues[1]).toFixed(1);
      } else {
        text = num + ' ~ ' + CValues[Number(l) + 1]
      }
    } else
    /*升序*/ {
      let num = (CValues[l]).toFixed(1);
      if (l == 0) {
        text = num
      } else {
        text = num + ' ~ ' + (CValues[l - 1]).toFixed(1)
      }
    }
    // console.log(Colors[l], l)
    if(!Colors[l])break
    ctx_c.fillStyle = '#333';
    ctx_c.fillText(text, 45, l * clen + 15); //文本
    // ctx_c.fillStyle = 'rgb(' + Colors[l].R + ',' + Colors[l].G + ',' + Colors[l].B + ')';
    ctx_c.fillStyle = 'rgb(' + Colors[l][0] + ',' + Colors[l][1] + ',' + Colors[l][2] + ')';
    ctx_c.fillRect(2, l * clen, 35, clen);
    ctx_c.fillStyle = 'rgb(255,255,255)';
    ctx_c.fillRect(2, l * clen, 35, 1);
  }
  return canvas_c.toDataURL('image/png');
}
export function drawCard1(CValues, Colors, clen) {
  // console.log(CValues, Colors)
  var isMax = CValues[0] < CValues[CValues.length - 1]; // 判断 升序降序
  var canvas_c = document.createElement('canvas');
  var ctx_c = canvas_c.getContext('2d');
  var ls = CValues.length;
  canvas_c.height = clen * ls;
  canvas_c.width = 120;
  // canvas_c.width = 70;
  for (var l in CValues) {
    let text = CValues[l];
    if (isMax /* 降序*/) {
      // console.log('%c �','color:'+'rgb(' + Colors[l][0] + ',' + Colors[l][1] + ',' + Colors[l][2] + ')', 'text', text, 'index', l)
      let num = (CValues[l]).toFixed(1);
      if (l == 0) {
        text = num
      } else {
        text = num + ' ~ ' + CValues[Number(l) + 1]
      }
    } else
    /*升序*/ {
      let num = (CValues[l]).toFixed(1);
      if (l == 0) {
        text = ">" + num
      } else {
        text = num + ' ~ ' + (CValues[l - 1]).toFixed(1)
      }
    }
    // console.log(Colors[l], l)
    if(!Colors[l])break
    ctx_c.fillStyle = '#333';
    ctx_c.fillText(text, 45, l * clen + 15); //文本
    // ctx_c.fillStyle = 'rgb(' + Colors[l].R + ',' + Colors[l].G + ',' + Colors[l].B + ')';
    ctx_c.fillStyle = 'rgb(' + Colors[l][0] + ',' + Colors[l][1] + ',' + Colors[l][2] + ')';
    ctx_c.fillRect(2, l * clen, 35, clen);
    ctx_c.fillStyle = 'rgb(255,255,255)';
    ctx_c.fillRect(2, l * clen, 35, 1);
  }
  return canvas_c.toDataURL('image/png');
}

function resize(src_data, dst_height, dst_width) {
  var ori_height = src_data.length;
  var ori_width = src_data[0].length;
  //ori_height, ori_width, channel = src_data.shape
  var ratio_height = ori_height / dst_height;
  var ratio_width = ori_width / dst_width;
  var dst_data = new Array(dst_height); //创建新数组

  for (var y = 0; y < dst_height; ++y) {
    dst_data[y] = new Array(dst_width);
    for (var x = 0; x < dst_width; ++x) {
      try {
        var x_ori = parseInt(x * ratio_width);
        var y_ori = parseInt(y * ratio_height);

        dst_data[y][x] = src_data[y_ori][x_ori];
      } catch (e) {
        console.log(e);
      }
    }
  }
  return dst_data;
}