
export function getColor (key){
  const obj = {
    //温度
    TMP:{
      section:[-16, -12, -8, -6, -4, -2, 0, 2, 4, 6, 8, 12, 16, 20, 24, 26, 28, 30, 32, 35, 37, 38, 40],
      color: [
        [0, 0, 255],
        [0, 40, 255],
        [0, 80, 255],
        [0, 120, 255],
        [0, 160, 255],
        [0, 200, 255],
        [0, 240, 255],
        [0, 255, 215],
        [0, 255, 175],
        [0, 255, 135],
        [0, 255, 95],
        [0, 255, 55],
        [40, 255, 0],
        [80, 255, 0],
        [120, 255, 0],
        [160, 255, 0],
        [200, 255, 0],
        [240, 255, 0],
        [255, 215, 0],
        [255, 175, 0],
        [255, 135, 0],
        [255, 95, 0],
        [255, 55, 0],
        [255, 15, 0]
      ]
    },
    //湿度
    ERH:{
      section:[
        66,69.3,72.6,75.9,79.1,82.4,85.7,88.9,92.2,95.5,98.8
      ],
      color:[
        [255,19,1],
        [255,95,19],
        [255,146,34],
        [255,176,43],
        [245,220,56],
        [157,255,68],
        [0,255,148],
        [0,203,253],
        [39,138,250],
        [51,66,249],
        [53,41,249],
      ]
    },
    //降雨
    ER03:{
      section:[ 0.1, 2.5, 5, 10, 15, 25, 50, 100, 300],
      color:[
      [255,255,255],
      [98,222,117],
      [45,186,81],
      [41,135,56],
      [106,177,244],
      [55,0,242],
      [246,0,241],
      [203,0,143],
      [141,2,83],
      ]
    },
    //风速风向
    EDA10:{
      section:[0.2, 1.5,3.3, 5.4,7.9,10.7,13.8,17.1,20.7,24.4,28.4,32.6,36.9,41,45,45.2],
      color:[
        [0,255,255,0],
        [230, 246, 234],
        [211, 245, 221],
        [187, 245, 202],
        [151, 232, 173],
        [153, 210, 202],
        [155, 188,232],
        [107, 157, 225],
        [59, 126, 219],
        [43, 92, 194],
        [28, 59, 169],
        [17, 44, 144],
        [7, 30, 120],
        [70, 25 ,129],
        [134, 21, 138],
        [200, 17, 169],
      ]
    },
    //能见度
    VIS:{
      section:[0.2*1000, 0.5 * 1000, 1 * 1000, 2 * 1000, 3 * 1000, 5 * 1000, 10 * 1000, 20 * 1000, 30 * 1000],
      color:[    
        [115,40,0],
        [158,0,255],
        [255,15,0],
        [255,85,1],
        [255,174,80],
        [255,255,0],
        [123,251,50],
        [159,217,247],
        [200,232,255],
        [241,245,253],
        [0,115,40]
      ]
    }
  }
  return obj[key] || {}
}