

import request from "@/utils/request.js";
import axios from "axios";


// /api/Wechat/GetGeDianYbData?lat=34.25&lng=116.25
export function wgybgetEC(params) {
  return request({
    url: "/api/Wechat/GetGeDianYbData",
    method: "GET",
    params
  });
}

export function getJsonData(url) {
  return axios.get(url);
  // return request({
  //   url: url,
  //   method: "GET",
  // });
}

// GET /api/Wechat/GetGeDianList
// 获取格点文件 "/api/Wechat/GetGeDianList"
export function GetGeDianList(params) {
  return request({
    url: "/api/Wechat/GetGeDianList",
    method: "GET",
    params
  });
}
// GET /api/Wechat/GetGeDianData
// 获取格点预报数据 "/api/Wechat/GetGeDianData"
export function GetGeDianData(params) {
  return request({
    url: "/api/Wechat/GetGeDianData",
    method: "GET",
    params
  });
}
// POST /api/Wechat/AddFlowInfo
// 微信页面访问+1 "/api/Wechat/AddFlowInfo"
export function AddFlowInfo(params) {
  return request({
    url: "/api/Wechat/AddFlowInfo",
    method: "GET",
    params
  });
}

//GET /api/Wechat/GetGeDianYbDataS
// 根据经纬度获取全国格点逐小时预报
export function GetGeDianYbDataS(params) {
  return request({
    url: "/api/Wechat/GetGeDianYbDataS",
    method: "GET",
    params
  });
}

export function tqybweather(params) {
    return request({
      url: "http://www.nmc.cn/rest/weather",
      method: "GET",
      params
    });
}