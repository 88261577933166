<template>
  <div class="content_box wgyb_box">
    <div style="width:100%; height:100%;" id="wgybMap"></div>
    <div class="op_layer title">
      <p>智能网格预报</p>
    </div>
    <div class="op_layer search">
      <van-search class="search1" left-icon="" right-icon="search" v-model="search" shape="round" @search="onSearch"
        @cancel="onSearch" background="rgb(79, 192, 141, 0)" placeholder="输入经纬度查询：124.45861,47.63159" />
    </div>
    <div class="op_layer element_list">
      <ul>
        <li class="element_item" :class="{ 'switch': it.open }" v-for="it of elementList" :key="it.name"
          @click="switchEl(it, 'elementList')">
          <ul class="wendu_box" v-if="it.tid == 'wendu'" v-show="isWenduLits">
            <li class="element_item" :class="{ 'switch': item.open }" v-for="item of wenduState" :key="item.name"
              @click.stop="switchEl(item, 'wenduState')">
              <img v-show="!item.open" :src="require('../../assets/elementmenu/' + item.img + '1' + '.png')" alt="" />
              <img v-show="item.open" :src="require('../../assets/elementmenu/' + item.img + '.png')" alt="" />
              <div>{{ item.name }}</div>
            </li>
          </ul>
          <img v-show="!it.open" :src="require('../../assets/elementmenu/' + it.img + '1' + '.png')" alt="" />
          <img v-show="it.open" :src="require('../../assets/elementmenu/' + it.img + '.png')" alt="" />
          <div>{{ it.name }}</div>
        </li>
      </ul>
    </div>
    <div class="op_layer isSebantu" :class="{ 'openSebantu': isSebantu }" @click="sebantuOpen"> 色斑图</div>
    <div class="op_layer choice_time">
      <div class="date_item" @click="isChoiceTime = !isChoiceTime">
        {{ selectTime.times }}
      </div>
      <transition name="van-fade">
        <ul class="time_ul" v-show="isChoiceTime">
          <li @click="selectTimeFun(time)" v-for="time of timeList" :key="time.times">{{ time.times }}</li>
        </ul>
      </transition>
    </div>
    <div class="op_layer sbttl" v-if="sbttl">
      <img :src="sbttl" alt="">
    </div>
    <van-popup v-model="facePlate" :overlay="false" position="bottom" :style="{ height: '60%' }">
      <div class="close_panel">
        <h3>智能网格预报</h3>
        <van-radio-group @change="switchHorizontal" class="" v-model="GeDianY" direction="horizontal">
          <van-radio name="1">本省</van-radio>
          <van-radio name="2">全国</van-radio>
        </van-radio-group>
        <!-- <span>07-07 08:00</span> -->
        <van-icon @click="facePlate = false" name="arrow-down" color="#15AAEC" />
      </div>
      <div class="ec_data" ref="ECdata"></div>
      <ul class="grid_time">
        <li v-for="item of wgybEcData" :key="item.times">
          <p>{{ item.week }}</p>
          <p>{{ item.time }}</p>
          <p>{{ item.minWd }}°C ~ {{ item.maxWd }}°C </p>
          <p>{{ item.feng }}</p>
        </li>
      </ul>
      <p class="Tips">注：数据由格点预报服务产品自动生成，仅供参考。</p>
    </van-popup>
    <van-overlay z-index="10000" :show="overlayLoading">
      <van-loading class="wrapper" type="spinner" />
    </van-overlay>
  </div>
</template>

<script>
import { Formats, getweektime } from "@/utils/common.js"
import { gdybEc } from "./wgyb.js"
import { GridValueLayer,MyLayers } from "./GridLayer.js"
import { wgybgetEC,GetGeDianYbDataS, GetGeDianList, getJsonData } from "./api.js"
import { Getfengxiang, WindLevel } from "@/utils/Getfengxiang.js"
import { getColor } from "./element.js"
import { drawCanvas, drawCard } from "@/utils/drawSbt.js"
var rateChart; // 存放ec 数据
var mapLayerr;  // 地图色斑图图层管理
export default {
  name: 'wgyb',
  data() {
    return {
      GeDianY:'1',
      tdMap: null,
      sbttl: null, // 色斑图图例
      coordinate: null,
      facePlate: false,
      overlayLoading: false,
      search: "", // 搜索绑定
      isChoiceTime: false,
      isSebantu: true,
      isWenduLits: false,
      wgybGdtList: [], // 色斑图数据列表
      selectTime: '', // 选择的时间格点预报路径
      wgybEcData: [],
      timeList: [], // 选择时间列表
      gridLayer: null, // 数值图
      elementList: [
        { name: '气温', img: "wendu", key: 'TMP', tid: 'wendu', open: true },
        { name: '风', img: "fengxiang", key: 'EDA10', tid: 'fengxiang', open: false },
        { name: '降水', img: "jiangyu", key: 'ER03', tid: 'jiangyu', open: false }
      ],
      wenduState: [
        { name: '整点', img: "wendu", open: true },
        // { name:'最高温', img:"wendu", open:false },
        // { name:'最低温', img:"wendu", open:false }
      ]
    }
  },
  created() {
    wgybgetEC({ lat: mapCenter[0], lng: mapCenter[1] }).then(res => {
      console.log(res)
    })
  },
  mounted() {
    setTimeout(() => {
      this.mapCreat();
    }, 500);
  },
  methods: {
    // 要素echatrs
    gdybFunEc(obj) {
      if (rateChart != null && rateChart != "" && rateChart != undefined) {
        rateChart.dispose();//销毁
      }
      var dom = this.$refs.ECdata;
      rateChart = gdybEc(dom, obj);
    },

    // 点击获取该区域的要素数据
    EcGeDianData() {
      this.overlayLoading = true;
      var fun = this.GeDianY==1?wgybgetEC:GetGeDianYbDataS;
      fun(this.coordinate).then(res => {
        this.overlayLoading = false;
        const { state, data } = res;
        if (state != 'ok' || !data) return;
        // 解析近期天气
        let recent = [];
        let week = [];
        for (let i = 0, cycle = []; i < data.length; i++) {
          let it = data[i];
          let m = new Date(it.times).getHours();
          if (i + 1 == 1 || i + 1 == 8 || i + 1 == 16) week.push(getweektime(new Date(data[i].times).getTime(), true));
          cycle.push(it);
          //  昨天的数据 截止到今天早上8点
          if (m === 8) {
            let it = cycle[0];
            var list = cycle.sort((a, b) => Number(a.wd) - Number(b.wd));
            var date = new Date(it.times);
            recent.push({
              minWd: list[0].wd,
              maxWd: list[list.length - 1].wd,
              ...it,
              time: `${date.getMonth() + 1}月${date.getDate()}日`,
              feng: Getfengxiang(it.fx) + WindLevel(it.fs)
            })
            cycle = [];
          }
        }
        this.wgybEcData = recent
        week.forEach((it, index) => { recent[index].week = it });
        console.log(this.wgybEcData);
        // ec图 数据
        let obj = { wd: [], fx: [], fs: [], js: [], times: [], hours: [] };
        data.forEach(it => {
          obj.wd.push(it.wd);
          obj.fx.push(it.fx);
          obj.fs.push(it.fs);
          obj.js.push(it.js);
          obj.times.push(it.times);
        })
        console.log(res)
        this.gdybFunEc(obj);
      })
    },

    /**清除覆盖物 */
    cleanMapLayerr(isOpen) {
      this.tdMap.map.remove(mapLayerr); //清除所有覆盖物
      // if (isOpen) $('#gdybElType').children().removeClass('elTypeActive');
    },
    // 本省 全国 预报
    switchHorizontal(e){
      this.EcGeDianData()
    },
    // 获取格点预报色斑图
    async drawgdyb(el) {
      const { key } = el;
      var time = new Date()
      const obj = {
        type: key,
        time: Formats(time), // 开始时间
        hour: 3, // 小时默认传3
      }
      const res = await GetGeDianList(obj);
      this.timeList = res.data;
      this.selectTimeFun(this.timeList[0]);
    },

    // 色斑图
    async drawgdybImg() {
      this.overlayLoading = true;
      if (mapLayerr) this.cleanMapLayerr();
      let item = this.elementList.filter(it => it.open)[0];
      const res = await getJsonData(radarUrl + this.selectTime.FileUrl);
      const jsonData = res.data;
      this.abnormal(jsonData.Data);
      const { section, color } = getColor(item['key']); // 更改可以值 注意需要颜色生成方法的key
      const { Data, Height, Width, MaxLat, MaxLon, MinLat, MinLon, times } = jsonData;
      // console.log()
      console.log(jsonData)
      // MyLayers.initialize({
      //       map: this.tdMap.map, tdMap:this.tdMap,  gridValueVisible: true, gridDistanceScale:5
      // })
      // MyLayers.setData(jsonData);
      let Range = { maxlat: MaxLat, maxlng: MaxLon, minlat: MinLat, minlng: MinLon };
      let img = drawCanvas(Data, Range, { section, color });
      this.sbttl = drawCard(section, color, 15);
      mapLayerr = this.tdMap.imageLayerr(Range, img);
      this.overlayLoading = false;
    },

    // 过滤异常数据
    abnormal(list) {
      // if(key) return list;
      var error = [9999];
      var col = list.length;
      for (let y = 0; y < col; y++) {
        var row = list[y].length;
        for (let x = 0; x < row; x++) {
          if (error.includes(list[y][x])) list[y][x] = 0;
        }
      }
    },

    // 色斑图开关
    sebantuOpen() {
      this.isSebantu = !this.isSebantu;
      if (this.isSebantu) {
        this.drawgdybImg();
      } else {
        this.cleanMapLayerr();
        this.sbttl = null
      }
    },

    // 选择时间打开
    selectTimeFun(time) {
      this.selectTime = time;
      this.isChoiceTime = false
      this.drawgdybImg()
    },

    // 选择要素
    switchEl(el, key) {
      console.log(el, key);
      // if(key =='elementList') this.wenduState.forEach(it=>it.open=false);
      this[key].forEach((it) => { it.open = false });
      el.open = true;
      this.drawgdyb(el);
      // if(el.tid == 'wendu' && el.open) this.isWenduLits = true
      // else  this.isWenduLits = false
    },

    // 键盘 空格 回车 事件触发经纬度查询
    onSearch(e) {
      // console.log(e)
      // alert(e)
      var i = e.indexOf(',');
      if (i < 0) return
      var str = e.split(',');
      this.facePlate = true
      this.coordinate = { lat: str[0], lng: str[1] };
      this.tdMap.clearMark() // 清除标注点
      this.tdMap.addMark(str[0],str[1], require('../../assets/dianwei.png'), '') // 添加标注点
      this.EcGeDianData();
      console.log(str);
    },

    // 地图创建
    mapCreat() {
      // 来源于index.html中引入的 ./public/js/map/gdMap.js
      tdMap.map = new AMap.Map('wgybMap', {
        zoom: 8,
        center: [mapCenter[0], mapCenter[1]],
        resizeEnable: true,
        expandZoomRange: true,
        zoomEnable: true,
        scrollWheel: true
      });
      tdMap.map.on("zoomchange",function(){
          console.log('zoomchange')
          if(MyLayers.gridLayer) MyLayers.gridLayer.reset();
      })
      this.drawgdyb(this.elementList[0]);
      this.tdMap = tdMap;
      this.tdMap.addAreaLine(230200, 3);
      this.tdMap.map.on('click', (ev) => {
        var { lat, lng } = ev.lnglat;
        this.search = lng+','+lat
        this.coordinate = { lat, lng };
        this.tdMap.clearMark() // 清除标注点
        this.tdMap.addMark(lng, lat, require('../../assets/dianwei.png'), '') // 添加标注点
        this.facePlate = true
        this.EcGeDianData();
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.wgyb_box {
  position: relative;

  .op_layer {
    position: absolute;
  }

  .openSebantu {
    color: #fff !important;
    background-color: rgba(35, 97, 206, .9) !important;
  }

  .Tips {
    color: #333;
    font-size: 14px;
    text-align: center;
  }

  .grid_time {
    display: flex;
    color: #333;
    border: 1px solid #ccc;
    margin: 0 10px;

    li:not(:last-child) {
      border-right: 1px solid #ccc;
    }

    li {
      width: 33%;
      text-align: center;

      p:first-child {
        font-size: 16px;
        color: #13A9EC;
      }

      p {
        margin: 8px 0;
        font-size: 13px;
      }
    }
  }

  .sbttl {
    bottom: 0%;
    right: 1%;
  }

  .ec_data {
    width: 95%;
    height: 260px;
    margin: auto;
  }

  .close_panel {
    padding: 5px 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #333;

    h3 {
      margin: 0;
    }

    span {
      font-size: 13px;
      color: #c9c9c9;
    }

    i {
      font-size: 26px;
      padding: 0 10px;
    }
  }

  .isSebantu {
    left: 2%;
    top: 45%;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    background-color: rgba(234, 243, 252, .9);
    color: #628FF3;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
  }

  .choice_time {
    right: 2%;
    top: 15%;
    font-size: 16px;

    .date_item {
      color: #13A9EC;
      font-weight: bold;
      padding: 10px 15px;
      background-color: #fff;
      box-shadow: 2px 3px 5px #888888;
    }

    .time_ul {
      height: 250px;
      overflow: auto;
      margin-top: 3px;
      border: 1px solid #628FF3;
      border-radius: 3px;
      padding: 5px 10px;
      background-color: #fff;

      li {
        margin: 10px 0;
        color: #333;
      }
    }
  }

  .element_list {
    left: 2%;
    top: 15%;

    .switch {
      background-color: rgba(35, 97, 206, .9) !important;

      >div {
        color: #fff !important;
      }
    }

    .element_item {
      position: relative;
      border-radius: 10px;
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      margin-left: 10px;
      // background-color: rgba(35,97,206,.9);
      background-color: rgba(234, 243, 252, .9);

      >img {
        display: block;
        width: 30px;
        margin: auto;
      }

      >div {
        white-space: nowrap;
        font-size: 12px;
        text-align: center;
        color: #628FF3;
      }

      .wendu_box {
        position: absolute;
        top: 0%;
        left: 50px;
        display: flex;

      }
    }

    >ul>.element_item {
      margin: 10px 0;
    }
  }

  .title {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 40px;
    background-color: #628FF3;
    text-align: center;

    p {
      line-height: 40px;
      margin: 0;
    }
  }

  .search {
    top: 6%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 2px 3px 5px #888888;
  }

  /**搜索框样式 */
  .search1 {
    width: 100%;
    padding: 5px;

    ::v-deep .van-cell {
      background-color: #fff;
    }
  }
}
</style>