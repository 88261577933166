
import echarts from "@/utils/echarts";
import { Formats, Format, getweektime } from "@/utils/common.js"
import { Getfengxiang } from "@/utils/Getfengxiang.js"
export function gdybEc(dom,data){
  const { fs,fx:fx1,js,times,wd } = data;
  var rateChart = echarts.init(dom);
  const colors = ['#D40000', '#EC64E2', '#6BCEE7']
  let option = {
    color: colors,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter:function(val,index){
        var time = val[0].axisValue;
        // console.log(fx1,[val[0].dataIndex])
        var fx = Getfengxiang(fx1[val[0].dataIndex]);
        var obj = {'气温':'°C','风速':'m/s','降水':'mm'}
        var a = val.map(it=>`${it.marker} ${it.seriesName}：${it.data} ${obj[it.seriesName]}<br />`).join(' ');
        return time + '<br />' + a + ' 风向：'+ fx
      }
    },
    legend: {
      data: ['气温','风速','降水']
    },
    grid: {
      top: 70,
      bottom: 50,
    },
    xAxis: [
      {
        type: 'category',
        splitLine:{
          show:true,
        },
        axisLabel:{
          formatter: function (value) {
            var a = new Date(value).getHours();
            return a
          },
        },
        data: times
      },
      {
        type: 'category',
        splitLine:{
          show:true,
        },
        axisLabel: {
          interval:8,
          formatter:function(value){
            var week = getweektime(new Date(value).getTime());
            return week + Formats(new Date(value),'MM/dd');
          }
        },
        data: times
      }
    ],
    yAxis: [
      {
        type: 'value',
        position: 'left',
        alignTicks: true,
        axisLabel: {
          fontSize: 10,
          formatter: '{value}°C'
        }
      },
      {
        type: 'value',
        // name:'m/s',
        position: 'right',
        alignTicks: true,
        axisLabel: {
          fontSize: 10,
          formatter: '{value}m/s'
        },
      },
    ],
    dataZoom: [
      //给x轴设置滚动条
      {
        height: 3,
        start: 0, //默认为0
        end: 100 - 1500 / 31, //默认为100
        type: "slider",
        show: true,
        xAxisIndex: [0,1],
        handleSize: 0, //滑动条的 左右2个滑动条的大小
        bottom: "0%",
        left: "90%", //左边的距离
        right: "90%",
        borderColor: "#000",
        fillerColor: "#01DFA5",
        borderRadius: 5,
        backgroundColor: "#33384b", //两边未选中的滑动条区域的颜色
        showDataShadow: false, //是否显示数据阴影 默认auto
        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
        realtime: true, //是否实时更新
        filterMode: "filter",
      },
      {
        type: "inside",
        show: true,
        xAxisIndex: [0,1],
        start: 0, //默认为1
        end: 100 - 1500 / 31, //默认为100
      },
  ],
    series: [
      {
        name: '气温',
        type: 'line',
        smooth: true,
        label: {
          show: true,
          position: 'top'
        },
        data: wd
      },
      {
        name: '风速',
        type: 'line',
        smooth: true,
        yAxisIndex: 1,
        label: {
          show: true,
          position: 'top'
        },
        data: fs
      },
      {
        name: '降水',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
      label: {
        show: true,
        position: 'top'
      },
        data: js
      },
    ]
  };
  return rateChart.setOption(option);
}


